import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade, Autoplay } from "swiper";
import { Link } from "react-router-dom";
const HeroThree = () => {
  return (
    <div className="hero-wrapper hero-3">
      <div className="hero-3-slider global-carousel">
        <Swiper
          loop={true}
          modules={[FreeMode, Navigation, Thumbs, EffectFade, Autoplay]}
          autoplay={{ delay: 8000, disableOnInteraction: false }}
          effect="fade"
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
        >
          <SwiperSlide>
            <div
              className="hero-slide"
              style={{
                backgroundImage: "url(assets/img/hero/hero_bg_3_1.png)",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xxl-6 col-xl-5 col-lg-6">
                    <div className="hero-style3">
                      <div
                        className="hero-subtitle text-white"
                        data-ani="slideinup"
                        data-ani-delay="0s"
                      >
                        <span>
                          <img
                            src="assets/img/hero/hero_shape_3.png"
                            alt="Bienvenidos a Ralte"
                          />
                          Bienvenidos a Ralte 
                        </span>{" "}
                      </div>
                      <h2
                        className="hero-title text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.1s"
                      >
                        Pernos Automotrices RALTE – Fabricación Propia con Máxima Calidad
                      </h2>
                      <p
                        className="hero-text text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.2s"
                      >
                        En RALTE, fabricamos pernos de alta resistencia ideales para aplicaciones industriales y automotrices.
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinup"
                        data-ani-delay="0.3s"
                      >
                        <Link to="/" className="btn">
                        Explora nuestros Pernos
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div
                      className="video-wrap text-lg-center"
                      data-ani="slideinup"
                      data-ani-delay="0.2s"
                    >
                      <a
                        href="#"
                        className="play-btn style4 popup-video"
                      >
                        <i className="fas fa-solid fa-play" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className="hero-slide"
              style={{
                backgroundImage: "url(assets/img/hero/hero_bg_3_2.png)",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xxl-6 col-xl-5 col-lg-6">
                    <div className="hero-style3">
                      <div
                        className="hero-subtitle text-white"
                        data-ani="slideinup"
                        data-ani-delay="0s"
                      >
                        <span>
                          <img
                            src="assets/img/hero/hero_shape_3.png"
                            alt="Bienvenidos a Ralte"
                          />
                          Bienvenidos a Ralte
                        </span>{" "}
                      </div>
                      <h2
                        className="hero-title text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.1s"
                      >
                        Birlos Automotrices RALTE – Seguridad y Variedad para Todas las Aplicaciones
                      </h2>
                      <p
                        className="hero-text text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.2s"
                      >
                        Con RALTE, encuentra una amplia selección de birlos, incluyendo extrapesados, para la mejor seguridad en carretera.
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinup"
                        data-ani-delay="0.3s"
                      >
                        <Link to="/" className="btn">
                        Ver Birlos Disponibles
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div
                      className="video-wrap text-lg-center"
                      data-ani="slideinup"
                      data-ani-delay="0.2s"
                    >
                      <a
                        href="#"
                        className="play-btn style4 popup-video"
                      >
                        <i className="fas fa-solid fa-play" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className="hero-slide"
              style={{
                backgroundImage: "url(assets/img/hero/hero_bg_3_2.png)",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xxl-6 col-xl-5 col-lg-6">
                    <div className="hero-style3">
                      <div
                        className="hero-subtitle text-white"
                        data-ani="slideinup"
                        data-ani-delay="0s"
                      >
                        <span>
                          <img
                            src="assets/img/hero/hero_shape_3.png"
                            alt="Bienvenidos a Ralte"
                          />
                          Bienvenidos a Ralte
                        </span>{" "}
                      </div>
                      <h2
                        className="hero-title text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.1s"
                      >
                        Tuercas y Tornillos Caliper – Calidad de RALTE en Cada Pieza
                      </h2>
                      <p
                        className="hero-text text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.2s"
                      >
                        En RALTE, ofrecemos tuercas y tornillos caliper que destacan por su durabilidad y precisión.
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinup"
                        data-ani-delay="0.3s"
                      >
                        <Link to="/" className="btn">
                          Cotiza Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div
                      className="video-wrap text-lg-center"
                      data-ani="slideinup"
                      data-ani-delay="0.2s"
                    >
                      <a
                        href="#"
                        className="play-btn style4 popup-video"
                      >
                        <i className="fas fa-solid fa-play" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className="hero-slide"
              style={{
                backgroundImage: "url(assets/img/hero/hero_bg_3_2.png)",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xxl-6 col-xl-5 col-lg-6">
                    <div className="hero-style3">
                      <div
                        className="hero-subtitle text-white"
                        data-ani="slideinup"
                        data-ani-delay="0s"
                      >
                        <span>
                          <img
                            src="assets/img/hero/hero_shape_3.png"
                            alt="Bienvenidos a Ralte"
                          />
                          Bienvenidos a Ralte
                        </span>{" "}
                      </div>
                      <h2
                        className="hero-title text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.1s"
                      >
                        Fabricantes de Componentes Automotrices RALTE – Soluciones a tu Medida
                      </h2>
                      <p
                        className="hero-text text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.2s"
                      >
                        RALTE, fabricante de confianza en refacciones automotrices. Contáctanos para proyectos personalizados y soluciones al mayoreo
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinup"
                        data-ani-delay="0.3s"
                      >
                        <Link to="/" className="btn">
                          Conoce más sobre Nosotros
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div
                      className="video-wrap text-lg-center"
                      data-ani="slideinup"
                      data-ani-delay="0.2s"
                    >
                      <a
                        href="#"
                        className="play-btn style4 popup-video"
                      >
                        <i className="fas fa-solid fa-play" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

        </Swiper>
      </div>
    </div>
  );
};

export default HeroThree;
