import React from "react";
import { Link } from "react-router-dom";

const FooterAreaOne = () => {
  return (
    // <footer
    //   className="footer-wrapper footer-layout1"
    //   style={{ backgroundImage: "url(assets/img/bg/footer-bg1-1.png)" }}
    // >
      <footer
      className="footer-wrapper footer-layout1"
      style={{ backgroundColor: "#000" }}
    >
      <div className="container">
        <div className="widget-area">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Somos Ralte</h3>
                <div className="menu-all-pages-container">
                  <p className="footer-text mb-50">
                  RALTE es una empresa mexicana con experiencia <br/>  en la fabricación y distribución de refacciones automotrices <br/>  de alta calidad, especializada en pernos, birlos y kits de frenos. <br/>  Nos enfocamos en brindar soluciones duraderas y eficaces <br/>  para refaccionarias y distribuidores en México
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Productos </h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to="/">Pernos para puerta</Link>
                    </li>
                    <li>
                      <Link to="/">Birlos</Link>
                    </li>
                    <li>
                      <Link to="/">Birlos Extrapesado</Link>
                    </li>
                    <li>
                      <Link to="/">Tuercas RALTE</Link>
                    </li>
                    <li>
                      <Link to="/">Tornillo caliper</Link>
                    </li>
                    <li>
                      <Link to="/">Tuercas BI</Link>
                    </li>
                    <li>
                      <Link to="/">Otros</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget footer-widget">
                <h3 className="widget_title">Contacto</h3>
                <div className="widget-contact">
                  <p>
                    <Link to="tel:">55 5759 0160 </Link> <br/>
                    <Link to="tel:">55 5759 0158 </Link> <br/>
                    <Link to="tel:">55 5517 6784  </Link> <br/>
                    <Link to="tel:">55 8005 1779  </Link> <br/>
                  </p>
                  <p>
                  Norte 16 #4917, Maximino Avila Camacho,   <br /> Gustavo A. Madero, CDMX,  07380
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-xl-auto">
              <div className="widget footer-widget widget-newsletter">
                <h3 className="widget_title">Get In Touch</h3>
                <p className="footer-text mb-50">
                  Lorem Ipsum is simply is dumiomy is text Lorem Ipsum is simply
                </p>
                <form className="newsletter-form">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Your Email Address"
                      required=""
                    />
                  </div>
                  <button type="submit" className="btn">
                    <i className="fas fa-arrow-right" />
                  </button>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row gy-3 justify-content-md-between justify-content-center">
            <div className="col-auto align-self-center">
              <p className="copyright-text text-center">
                © 2018-2024 - Ralte. Todos los Derechos Reservados. 
              </p>
            </div>
            <div className="col-auto">
              <div className="footer-links">
                <Link to="/">Aviso de Privacidad  </Link>
                <Link to="/">Contacto</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterAreaOne;
