import React from "react";

const ProcessAreaOne = () => {
  return (
    <section className="process-area-1 space position-relative">
      <div className="portfolio-shape-img shape-mockup d-lg-block d-none">
        <img
          className="about1-shape-img-1 spin"
          src="/assets/img/normal/about_shape1-2.svg"
          alt="Fixturbo"
        />
        <img
          className="about1-shape-img-2 spin2"
          src="/assets/img/normal/about_shape1-1.svg"
          alt="Fixturbo"
        />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title">Tu Pedido, Paso a Paso</span>
              <h2 className="sec-title">
              Proceso de Cotización y Compra para Mayoristas
              </h2>
            </div>
          </div>
        </div>
        <div className="row gy-30">
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-1.svg" alt="Fixturbo" />
              </div>
              <h4 className="process-card-title">Explora y Selecciona</h4>
              <p className="process-card-text">
                Navega en nuestro buscador o catálogo en línea para encontrar los productos que necesitas. Agrega los artículos de tu interés a tu selección y accede a información detallada de cada uno.
              </p>
            </div>
          </div>
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card process-card-center">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-2.svg" alt="Fixturbo" />
              </div>
              <h4 className="process-card-title">Solicita tu Cotización</h4>
              <p className="process-card-text">
              Llena el formulario con los productos seleccionados. Nuestro equipo te contactará rápidamente para confirmar detalles y brindarte una cotización personalizada. También puedes escribirnos por WhatsApp para asistencia directa
              </p>
            </div>
          </div>
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-3.svg" alt="Fixturbo" />
              </div>
              <h4 className="process-card-title">Realiza el Pago y Recibe tu Pedido</h4>
              <p className="process-card-text">
              Efectúa el depósito de tu pedido y nosotros te lo enviamos a la dirección indicada. Recuerda preguntar sobre el pedido mínimo para envío o visita nuestro mostrador para recogerlo. Ideal para minoristas y compradores al por mayor que buscan volumen y calidad.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessAreaOne;
