import React, { useEffect, useState } from "react";
import HeaderTwo from "../components/HeaderTwo";
import HeroThree from "../components/HeroThree";
import ServiceAreaThree from "../components/ServiceAreaThree";
import AboutFour from "../components/AboutFour";
import MarqueeOne from "../components/MarqueeOne";
import PortfolioTwo from "../components/PortfolioTwo";
import ProcessAreaOne from "../components/ProcessAreaOne";
import ProcessAreaTwo from "../components/ProcessAreaTwo";
import CTAAreaTwo from "../components/CTAAreaTwo";
import PricingPlanTwo from "../components/PricingPlanTwo";
import ClientAreaThree from "../components/ClientAreaThree";
import FaqAreaTwo from "../components/FaqAreaTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import SubscribeOne from "../components/SubscribeOne";
import FooterAreaOne from "../components/FooterAreaOne";
import Preloader from "../helper/Preloader";


export function Home() {

    let [active, setActive] = useState(true);
    useEffect(() => {
        setTimeout(function () {
        setActive(false);
        }, 2000);
    }, []);

    return (
        <>
        {/* Preloader */}
        {active === true && <Preloader />}

        {/* Header Three */}
        <HeaderTwo />

        {/* Hero Four */}
        <HeroThree />

        {/* Service Area Two */}
        <ServiceAreaThree />

        {/* About Four */}
        <AboutFour />

        {/* Marquee One */}
        <MarqueeOne />

        {/* Portfolio Two */}
        <PortfolioTwo />

        {/* Process Area One */}
        <ProcessAreaOne />

        {/* Subscribe One */}
        <SubscribeOne />

        {/* Footer Area One */}
        <FooterAreaOne />
        </>
    )
}

export default Home