import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade, Autoplay } from "swiper"
import { Link } from "react-router-dom";
const PortfolioTwo = () => {
  return (
    <div className="portfolio-area-1 space overflow-hidden">
      <div className="container">
        <div className="row justify-content-between align-items-end">
          <div className="col-xl-5 col-lg-6">
            <div className="title-area">
              <span className="sub-title"> CONOCE LOS PRODUCTOS RALTE</span>
              <h2 className="sec-title">
              Fabricantes y Comercializadores Todo para Refacciones de Auto{" "}
                <img
                  className="title-bg-shape"
                  src="assets/img/bg/title-bg-shape.png"
                  alt="Fixturbo"
                />
              </h2>
            </div>
          </div>
          <div className="col-sm-auto">
            <div className="title-area">
              <div className="icon-box">
                <button className="slick-arrow default portfolio-button-next">
                  <i className="fas fa-arrow-left" />
                </button>
                <button className="slick-arrow default portfolio-button-prev">
                  <i className="fas fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row global-carousel gx-30 portfolio-slider1">
            <Swiper
              loop={true}
              navigation={{
                nextEl: ".portfolio-button-next",
                prevEl: ".portfolio-button-prev",
              }}
              spaceBetween={30}
              centeredSlides
              slidesPerView={1}
              slidesPerGroup={1}
              speed={2000}
              pagination={{ clickable: true }}
              autoplay={{ delay: 4000 }} // Ajuste de 4 segundos
              className="mySwiper"
              modules={[FreeMode, Navigation, Thumbs, EffectFade, Autoplay]} // Incluye Autoplay aquí
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 3,
                },
              }}
            >
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-1.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Pernos para Puerta RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                        Fabricamos pernos automotrices de alta resistencia en RALTE, perfectos para puertas y aplicaciones industriales
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-2.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Birlos RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                        RALTE ofrece birlos de todos los tipos, incluidos extrapesados, con la máxima seguridad y durabilidad.
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-3.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Tornillo Caliper RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                        Tornillos de precisión de RALTE para asegurar los calipers de freno y garantizar seguridad.
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-2.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Tuercas RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                        Disponibles en distintas configuraciones, las tuercas RALTE son ideales para aplicaciones automotrices diversas.
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-3.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Terminales RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                        Terminales automotrices RALTE, conectividad confiable para el sistema eléctrico de tu vehículo.
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-3.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Rondanas RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                        Rondanas fabricadas por RALTE, ajustables a múltiples aplicaciones automotrices, con durabilidad garantizada.
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-3.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Seguros Interior y Exterior RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                      Protección y resistencia en cada seguro RALTE, diseñados para uso interno y externo en el automóvil.
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-3.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Casquillos RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                        Casquillos RALTE que estabilizan y reducen el desgaste en piezas móviles de vehículos.
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img src="assets/img/portfolio/2-3.png" alt="Fixturbo" />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                        <Link to="/">Abrazaderas RALTE:</Link>
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                      Soluciones de sujeción eficientes RALTE para cables y mangueras en el automóvil.
                      </span>
                    </div>
                    <Link to="/" className="icon-btn">
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PortfolioTwo;
