import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
const AboutFour = () => {
  return (
    <div className="about-area-2 bg-smoke space-top" style={{paddingBottom:'0px'}}>
      <div className="container">
        <div className="row">
          <div className="col-xxl-7 col-xl-6">
            <div className="about-thumb2 mb-40 mb-xl-0">
              <div className="about-img-1">
                <img src="assets/img/normal/about_2-1.png" alt="Fixturbo" />
              </div>
              <div className="about-img-2">
                <img src="assets/img/normal/about_2-2.png" alt="Fixturbo" />
              </div>
              <div className="about-counter-wrap jump-reverse">
                <img src="assets/img/icon/about_icon2-1.svg" alt="Fixturbo" />
                <h3 className="about-counter">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter-number">
                          <CountUp delay={0} start={0} end={5} />
                          k+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h3>
                <h4 className="about-counter-text">Clientes Satisfechos</h4>
              </div>
              <div className="about-year-wrap2 movingX">
                <div className="about-year-grid-wrap">
                  <div className="icon">
                    <img
                      src="assets/img/icon/about_icon2-2.png"
                      alt="Fixturbo"
                    />
                  </div>
                  <h3 className="about-counter">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter-number">
                            <CountUp delay={0} start={0} end={20} />
                            k+
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h3>
                </div>
                <h4 className="about-year-text">Años de Experiencia</h4>
              </div>
            </div>
          </div>
          <div className="col-xxl-5 col-xl-6">
            <div className="about-content-wrap">
              <div className="title-area mb-30">
                <span className="sub-title">Calidad y eficiencia en cada pieza.</span>
                <h2 className="sec-title">
                Ralte - Fabricantes de Refacciones de Confianza
                </h2>
                <p className="sec-text">
                En RALTE, fabricamos pernos, seguros para frenos, tornillos de caliper y abrazaderas de suspensión. Nuestra experiencia en troquelado, maquinado y forjado nos permite ofrecer soluciones de alta calidad y costo competitivo para el mercado de refacciones automotrices.
                <br/><br/>
                Contamos con un departamento de diseño y herramientas para crear y reparar nuestros propios troqueles, dispositivos y herramientas, garantizando agilidad y precisión en cada proyecto.

                </p>
              </div>
              <div className="about-feature-wrap">
                <div className="icon">
                  <img src="assets/img/icon/about_icon2-3.svg" alt="Fixturbo" />
                </div>
                <div className="about-feature-wrap-details">
                  <h5 className="about-feature-title">
                    Compromiso con el Cliente
                  </h5>
                  <p className="about-feature-text">
                  Servicio personalizado y atención a los detalles en cada pedido{" "}
                  </p>
                </div>
              </div>
              {/* <div className="about-feature-wrap">
                <div className="icon">
                  <img src="assets/img/icon/about_icon2-4.svg" alt="Fixturbo" />
                </div>
                <div className="about-feature-wrap-details">
                  <h5 className="about-feature-title">Pro Drive Garage</h5>
                  <p className="about-feature-text">
                    Et purus duis sollicitudin dignissim habitant. Egestas nulla
                    quis venenatis cras sed{" "}
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
